import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Layout from "../layouts/layout-scroll"
import { graphql } from 'gatsby'

import Weeks from '../components/ui/weeks'

export default ({data}) =>
  <Layout>
    <Helmet htmlAttributes={{ class:'months' }} />
    <SEO title="Monthly pictures"
      description={data.site.siteMetadata.descriptions.months} />
    <h1>Monthly shots</h1>
    <div>
      <p style={{ textAlign: 'center' }}>
          A few shots to represent moments of each year.<br />
          Sometimes it's a nice DSLR picture, other ones are simply mobile pics
      </p>
      <p>
      </p>
      <Weeks/>
    </div>
  </Layout>

export const query = graphql`
  query MonthsQuery {
    site {
      siteMetadata {
        title
        descriptions {
          months
        }
      }
    }
  }
`
